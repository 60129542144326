
export default {
  name: 'FullPageDrop',
  mounted () {
    this.setupDropZone()
  },
  methods: {
    setupDropZone () {
      const dropZone = document.getElementById('fullpageDrop')
      const self = this
      function showDropZone () {
        dropZone.style.display = 'flex'
      }
      function hideDropZone () {
        dropZone.style.display = 'none'
      }
      function allowDrag (e) {
        e.dataTransfer.dropEffect = 'copy'
        e.preventDefault()
      }
      function handleDrop (e) {
        e.preventDefault()
        hideDropZone()
        self.handleDrop(e)
      }
      window.addEventListener('dragenter', function (e) {
        if (e.dataTransfer.effectAllowed !== 'move' && self.containsFiles(e)) { showDropZone() }
      })
      dropZone.addEventListener('dragenter', allowDrag)
      dropZone.addEventListener('dragover', allowDrag)
      dropZone.addEventListener('dragleave', function (e) {
        hideDropZone()
      })
      dropZone.addEventListener('drop', handleDrop)
    },
    handleDrop (ev) {
      this.$emit('drop', ev)
    },
    containsFiles (event) {
      if (event.dataTransfer.types) {
        for (let i = 0; i < event.dataTransfer.types.length; i++) {
          // eslint-disable-next-line eqeqeq
          if (event.dataTransfer.types[i] == 'Files') {
            return true
          }
        }
      }
      return false
    }
  }
}
